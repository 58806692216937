import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>Article non trouvé</h1>
        <p>
          Nous avons surement prévu d'écrire cet article et c'est en cliquant
          sur un lien d'un autre article que vous êtes arrivé dans ce vide
          intersidéral. Désolé pour cette gêne. Vous pouvez cliquer sur retour
          dans votre navigateur.
        </p>
        <p>
          Vous pouvez aussi nous envoyer un email à
          <a href="mailto:contact@labopichot.com">contact@labopichot.com</a>
          pour nous encourager à écrire cet article qui n'existe pas encore{" "}
        </p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
